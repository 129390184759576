import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { logo } from "../assets/logo";

export const NavBar = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [currentLink, setCurrentLink] = useState("");
    const hamburgerButtonRef = useRef(null);
    const sideNavRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        // Add event listener to the document object
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setCurrentLink(location.pathname);
    }, [location]);

    const sideLinks = [
        { title: "Home", url: "/" },
        { title: "Solutions", url: "/solutions" },
        { title: "Contact", url: "/contact" },
        { title: "Download", url: "/download" },
    ]

    const topLinks = [
        { title: "Home", url: "/" },
        { title: "Solutions", url: "/solutions" },
        { title: "Contact", url: "/contact" },
        { title: "Download", url: "/download" },
        { title: "Log In", url: "https://admin.spotparking.app/login" }
    ];

    const handleClickOutside = (event) => {
        if (
            sideNavRef.current &&
            !sideNavRef.current.contains(event.target) &&
            hamburgerButtonRef.current &&
            !hamburgerButtonRef.current.contains(event.target) // Check if the event target is outside the hamburger button
        ) {
            setSidebarOpen(false);
        }
    };

    const handleHamburgerClick = () => {
        setSidebarOpen(!sidebarOpen);
    }

    const sideLink = (title, url) => {
        let isActive = currentLink === url ? "active" : "";

        if (title === "Download") {
            return (
                <div className="select-none mt-2">
                    <hr className="flex place-self" />
                    <div className={`md:text-xl text-lg mt-4 md:hidden font-bold hover:text-spotYellow ${isActive ? "text-spotYellow" : "text-spotGray"}`}>
                        <a href={url} target="_blank" rel="noopener noreferrer">{title}</a>
                    </div>
                </div>
            )
        }

        if (title === "Solutions") {
            return (
                <div className="relative group select-none my-2">
                    <div className="md:hidden flex-col text-spotGray z-50 flex gap-4">
                        <Link to="/capacityMonitoring" onClick={() => setSidebarOpen(false)} className="text-lg font-bold">Capacity Monitoring</Link>
                        <Link to="/digitalGate" onClick={() => setSidebarOpen(false)} className="text-lg font-bold">Digital Gate</Link>
                        <Link to="/automatedEnforcement" onClick={() => setSidebarOpen(false)} className="text-lg font-bold">Automated Enforcement</Link>
                    </div>
                </div>
            )
        }

        return (
            <div className={`md:text-lg select-none text-lg md:hidden font-bold hover:text-spotYellow ${isActive ? "text-spotYellow" : "text-spotGray"}`}>
                <Link to={url} onClick={() => setSidebarOpen(false)} >{title}</Link>
            </div>
        );
    }

    const topLink = (title, url) => {
        const isActive = currentLink === url ? "active" : "";

        if (title === "Solutions") {
            return (
                <div className="relative group">
                    <div className={`md:text-xl text-3xl hidden md:flex font-bold hover:text-spotYellow ${isActive ? "text-spotYellow" : "md:text-white text-spotGray"}`}>
                        <Link to={url} aria-label={`${title} page`}>{title}</Link>
                    </div>
                    <div className="absolute hidden md:group-hover:flex md:flex-col p-2 bg-gray-700/80 backdrop-blur-lg rounded-xl font-rubik text-white shadow-lg z-50">
                        <Link to="/capacityMonitoring" className="block rounded-lg px-4 py-2 text-sm w-52 hover:bg-gray-500" aria-label="Capacity Monitoring page">Capacity Monitoring</Link>
                        <Link to="/digitalGate" className="block rounded-lg px-4 py-2 text-sm w-52 hover:bg-gray-500" aria-label="Digital Gate page">Digital Gate</Link>
                        <Link to="/automatedEnforcement" className="block rounded-lg px-4 py-2 text-sm w-52 hover:bg-gray-500" aria-label="Automated Enforcement page">Automated Enforcement</Link>
                    </div>
                </div>
            );
        };

        return (
            <div className={`md:text-xl text-3xl font-bold hover:text-spotYellow ${isActive ? "text-spotYellow" : "md:text-white text-spotGray"}`}>
                <Link aria-label={title} to={url} onClick={() => setSidebarOpen(false)}>{title}</Link>
            </div>
        );
    };

    return (
        <header className="fixed top-0 w-screen z-[10000]">
            {/* DESKTOP */}
            <div className="fixed md:flex flex-row justify-end top-0 h-14 w-screen text-white text-3xl font-bold z-50 bg-gray-700/20 backdrop-blur-lg font-rubik hidden">
                <div className="mr-auto mt-1 ml-2 w-20">
                    <Link to="/" aria-label="Home page">
                        {logo(70, 50, "#FFE374")}
                    </Link>
                </div>
                <div className="frc gap-8 mr-8">
                    {topLinks.map((link, index) => {
                        return topLink(link.title, link.url);
                    })}
                </div>
            </div>

            {/* MOBILE */}
            <div className="fixed md:hidden flex flex-row justify-end top-0 w-screen h-16 bg-spotGray/20 z-50 backdrop-blur-md text-white shadow-lg font-rubik" ref={sideNavRef}>
                <button className="group fixed w-8 h-8 top-4 left-4 z-50" onClick={handleHamburgerClick} ref={hamburgerButtonRef}>
                    <div className="grid justify-items-center gap-1.5" ref={sideNavRef}>
                        <span className={`h-1 w-8 rounded-full ${sidebarOpen ? "bg-black rotate-45 translate-y-2.5" : "bg-white"} transition duration-700`}></span>
                        <span className={`h-1 w-8 rounded-full ${sidebarOpen ? "bg-black scale-x-0" : "bg-white"} duration-[0.5s] transition`}></span>
                        <span className={`h-1 w-8 rounded-full ${sidebarOpen ? "bg-black -rotate-45 -translate-y-2.5" : "bg-white"} transition duration-700`}></span>
                    </div>
                </button>
                <div className={`fixed flex flex-col gap-4 top-0 left-0 h-screen w-[62vw] bg-white text-spotGray shadow-lg -translate-x-full transition-transform duration-500 z-30 ${sidebarOpen ? "translate-x-0" : null}`} ref={sideNavRef}>
                    <div className="text-4xl text-spotGray ml-4 mb-4 mt-20 flex flex-col gap-2">
                        {sideLinks.map((link, index) => {
                            return sideLink(link.title, link.url);
                        })}
                    </div>
                </div>
                <div className={"flex justify-center items-center h-8 select-none bg-white px-5 mr-6 mt-4 rounded-md font-bold shadow-lg text-spotGray hover:bg-gray-500"}>
                    <a href={"http://admin.spotparking.app/login"} target="_blank" rel="noopener noreferrer">Log In</a>
                </div>
            </div>
        </header>
    );
}