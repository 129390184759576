const API_KEY = process.env.REACT_APP_API_KEY;

export const fetchData = async (path) => {
    console.log("Fetching data from:", path);
    const baseUrl = "https://api.spotparking.app/SpotAPI"
    const url = `${baseUrl}${path}`;
    const jwt = localStorage.getItem('jwt') || "";
    try {
        const response = await fetch(url, {
            headers: {
                'x-api-key': API_KEY,
                'Authorization': jwt
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
};

export const fetchAllData = async (paths) => {
    // Use Promise.all to fetch all data concurrently
    const data = await Promise.all(Object.values(paths).map(path => fetchData(path)));

    const result = {};
    Object.keys(paths).forEach((key, index) => {
        result[key] = data[index];
    });

    return result;
};

export const fetchImage = async (path) => {
    console.log("Fetching data from:", path);
    const baseUrl = "https://api.spotparking.app/SpotAPI"
    const url = `${baseUrl}${path}`;
    const jwt = localStorage.getItem('jwt');
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'x-api-key': API_KEY,
                'Authorization': jwt
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response; // Return the image source

    } catch (error) {
        console.error('Error fetching the image:', error);
    }
};

export const postData = async (path, body) => {
    const baseUrl = "https://api.spotparking.app/SpotAPI"
    const url = `${baseUrl}${path}`;
    const jwt = localStorage.getItem('jwt');
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': API_KEY,
                'Authorization': jwt
            },
            body: JSON.stringify(body)
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error posting data:', error);
        return null;
    }
}