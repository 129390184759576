import React from "react";
import { Link } from "react-router-dom";
import { FaSquareXTwitter, FaLinkedin } from "react-icons/fa6";
import Tilt from 'react-parallax-tilt';

export const Footer = ({ bg, textColor }) => {
    const footerLinks = [
        { title: "LinkedIn", url: "https://www.linkedin.com/company/spotparking/", icon: <FaLinkedin /> },
        { title: "X", url: "https://twitter.com/spotparking_", icon: <FaSquareXTwitter /> },
    ];

    const learnMoreLinks = [
        {
            title: "Capacity Monitoring",
            url: "/capacityMonitoring",
            external: false
        },
        {
            title: "Digital Gate",
            url: "/digitalGate",
            external: false
        },
        {
            title: "Automated Enforcement",
            url: "/automatedEnforcement",
            external: false
        },
    ]

    const businessLinks = [
        {
            title: 'News',
            url: '/news',
            external: false
        },
        {
            title: "Contact",
            url: "/contact",
            external: false
        },
        {
            title: "Request a Demo",
            url: "/contact",
            external: false
        },
    ]

    const toolsLinks = [
        {
            title: "Download",
            url: "/download",
            external: true
        },
        {
            title: "Live Map",
            url: "/webmap",
            external: true
        },
        // {
        //     title: "ROI Calculator",
        //     url: "/calculator",
        //     external: false
        // },
    ]

    return (
        <div className="flex flex-col justify-center items-center h-fit w-full pt-10 bg-white overflow-hidden z-[2000] pb-4 gap-10" style={{ backgroundColor: bg }}>

            {/* LINE */}
            <hr className="w-[90%] border-t opacity-40" style={{ borderColor: textColor }} />

            {/* LINKS */}
            <div className="flex flex-col sm:flex-row gap-5 sm:gap-20 justify-start items-start w-full px-20 py-2">
                <div className="flex flex-col gap-2 justify-start">
                    <h2 className="font-bold text-lg" style={{ color: textColor }}>Learn More</h2>
                    {learnMoreLinks.map((link, index) => (
                        <Link
                            key={index}
                            to={link.url}
                            className="text-lg"
                            style={{ color: textColor, textDecoration: 'none' }}
                            onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
                            onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                            target={link.external ? "_blank" : "_self"} rel={link.external ? "noopener noreferrer" : ""}
                        >
                            {link.title}
                        </Link>
                    ))}
                </div>
                <div className="flex flex-col gap-2 justify-start">
                    <h2 className="font-bold text-lg" style={{ color: textColor }}>Tools</h2>
                    {toolsLinks.map((link, index) => (
                        <Link
                            key={index}
                            to={link.url}
                            className="text-lg"
                            style={{ color: textColor, textDecoration: 'none' }}
                            onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
                            onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                            target={link.external ? "_blank" : "_self"} rel={link.external ? "noopener noreferrer" : ""}
                        >
                            {link.title}
                        </Link>
                    ))}
                </div>
                <div className="flex flex-col gap-2 justify-start">
                    <h2 className="font-bold text-lg" style={{ color: textColor }}>For Business</h2>
                    {businessLinks.map((link, index) => (
                        <Link
                            key={index}
                            to={link.url}
                            className="text-lg"
                            style={{ color: textColor, textDecoration: 'none' }}
                            onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
                            onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                            target={link.external ? "_blank" : "_self"} rel={link.external ? "noopener noreferrer" : ""}
                        >
                            {link.title}
                        </Link>
                    ))}
                </div>
            </div>

            {/* BOTTOM */}
            <div className="flex flex-col justify-center items-center">
                {/* SOCIAL LINKS */}
                <div className="flex flex-row mx-auto mb-2">
                    {footerLinks.map((link, index) => (
                        <Tilt key={index} scale={1.1} tiltMaxAngleX={5} tiltMaxAngleY={5}>
                            <Link to={link.url} className="logo-link text-4xl" style={{ color: textColor }}>
                                {link.icon}
                            </Link>
                        </Tilt>
                    ))}
                </div>
                {/* COPYRIGHT ETC */}
                <div className="flex flex-col sm:flex-row gap-1 sm:gap-8 justify-center items-center">
                    <p className="flex text-spotGray text-center" style={{ color: textColor }} >&copy; 2025 Spot Parking. All rights reserved.</p>
                    <div className="flex flex-row justify-center items-center gap-2">
                        <Link
                            key={'Privacy Policy'}
                            to="/privacy-policy"
                            className="text-sm sm:text-lg text-center"
                            style={{ color: textColor }}
                            onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
                            onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                        >
                            Privacy Policy
                        </Link>
                        <div className="border-l opacity-40 h-5 w-1" style={{ borderColor: textColor }} />
                        <Link
                            key={'Terms and Conditions'}
                            to="/terms-and-conditions"
                            className="text-sm sm:text-lg text-center"
                            style={{ color: textColor }}
                            onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
                            onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                        >
                            Terms and Conditions
                        </Link>
                        <div className="border-l opacity-40 h-5 w-1" style={{ borderColor: textColor }} />
                        <Link
                            key={'Accessibility'}
                            to="/accessibility"
                            className="text-sm sm:text-lg text-center"
                            style={{ color: textColor }}
                            onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
                            onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                        >
                            Accessibility
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}